<template>
  <div>
    <section class="about--sec about--event" :id="slug">
      <div class="about--top">
        <h2>{{ data.title }}</h2>
        <div class="about--event--opt">
          <div class="d-select">
            <Select2
              id="about-sl-year"
              class="js-c-select"
              name="event-select-year"
              ref="select"
              v-model="eventSelectedYear"
              :options="myPastEventYearOptions"
              :settings="{ minimumResultsForSearch: Infinity }"
              @select="select2SelectEvent($event)"
              @open="select2OpenEvent($event)"
              @close="select2CloseEvent($event)"
            />

            <!-- <select
              name="event-select-year"
              id="about-sl-year"
              class="js-c-select"
            >
              <option value="">2021</option>
              <option value="">2020</option>
              <option value="">2019</option>
              <option value="">2018</option>
            </select> -->
          </div>
          <div class="m-select">
            <select
              name="event-select-year-m"
              @change="eventMobileOnChange"
              id=""
              class="m-select--input"
            >
              <option
                :value="year"
                v-for="(year, index) in myPastEventYearOptions"
                :key="index"
                >{{ year }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="about--event--body">
        <div class="row">
          <div
            class="col-12 col-sm-6"
            v-for="(event, index) in eventSelectedList.eventList"
            :key="index"
          >
            <div class="about--event--item">
              <div class="about--event--item_img">
                <div class="imgwrap">
                  <div
                    class="about--event--item_imgItem"
                    :style="{
                      backgroundImage: `url(${event.coverImage.large})`
                    }"
                  ></div>
                </div>
              </div>
              <h3>
                {{ event.title }}
              </h3>
              <p>
                {{ event.description }}
              </p>
              <a :href="event.url" target="_blank" class="btn btn--nor">{{
                $t("about.about_discover")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end of about us past event section -->
  </div>
</template>
<script>
import Select2 from "v-select2-component";
export default {
  name: "pass_event",
  components: { Select2 },
  data() {
    return {
      eventSelectedYear: "",
      eventSelectedList: []
    };
  },
  props: {
    data: Object,
    slug: String
  },
  computed: {
    myPastEventYearOptions() {
      let years = this.data.events.map(function(event) {
        return event.year;
      });

      return years;
    }
  },

  beforeMount() {},

  mounted() {
    if (this.data.events[0].year) {
      this.eventSelectedYear = this.data.events[0].year;
      this.eventSelectedList = this.data.events[0];
      this.$refs.select.select2.trigger("change");
    }
  },
  methods: {
    eventMobileOnChange(event) {
      this.eventSelectedYear = event.target.value;
      this.selectYersEventList(this.eventSelectedYear);
    },

    select2SelectEvent({ id, text }) {
      this.selectYersEventList(text);
    },

    selectYersEventList(year) {
      this.eventSelectedList = this.data.events.find(
        event => event.year == year
      );
    },

    select2OpenEvent(e) {
      // document.querySelector(".select2-results").style.display = "none";
      let thisContainer = document.querySelector(".d-select");
      let thisContainerRendered = thisContainer.querySelectorAll(
        ".select2-selection__rendered"
      );
      thisContainerRendered[0].classList.add("open");
    },
    select2CloseEvent(e) {
      let thisContainer = document.querySelector(".d-select");
      let thisContainerRendered = thisContainer.querySelectorAll(
        ".select2-selection__rendered"
      );
      thisContainerRendered[0].classList.remove("open");
    }
  }
};
</script>

<style lang="scss" scoped>
//selec2
</style>
